import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonToolbar,
  Form,
  Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GoPerson, GoCheck } from "react-icons/go";
import MeetingInfoForm from "views/partials/MeetingInfoForm";
import HostInfoForm from "views/partials/HostInfoForm";
import GuestInfoForm from "views/partials/GuestInfoForm";
import { useContextUser } from "context/UserContext";
import { addNewGuest } from "utils/meeting";
import useMockMeeting from "utils/useMockMeeting";
import { createUpdateMeeting } from "api";

/**
 * Page to create a new meeting.
 */
const NewMeeting: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [user] = useContextUser();
  const [loading, setLoading] = useState(false);
  const [meeting, setMeeting] = useState<IMeeting>({
    room: "",
    building: "",
    start: "",
    end: "",
    participants: [
      {
        title: user.title,
        firstName: user.firstName,
        lastName: user.lastName,
        company: "Mitsubishi Fuso",
        department: user.department,
        email: user.email,
        tel: user.tel,
        isGuest: false,
      },
      {
        title: "",
        firstName: "",
        lastName: "",
        company: "",
        department: "",
        email: "",
        tel: "",
        isGuest: true,
      },
    ],
  });
  useMockMeeting(meeting, setMeeting);

  const submitMeeting = async () => {
    const response = await createUpdateMeeting(meeting);
    setLoading(false);
    if (response.error !== "") toast.error(t(response.error));
    if (response.success && response.redirect) {
      history.push(response.redirect);
    }
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    setLoading(true);
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading(false);
    } else {
      submitMeeting();
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Container className="mt-3">
        <MeetingInfoForm
          meeting={meeting}
          onChange={setMeeting}
          editMode={true}
        />
        <HostInfoForm meeting={meeting} onChange={setMeeting} editMode={true} />
        <GuestInfoForm
          meeting={meeting}
          onChange={setMeeting}
          editMode={true}
        />
        <Row>
          <Col>
            <ButtonToolbar>
              <Button
                disabled={loading}
                variant="secondary"
                className="m-1"
                onClick={() => setMeeting(addNewGuest(meeting))}
              >
                <GoPerson className="mr-1" />
                {t("invitation.add_guest")}
              </Button>
              <Button
                disabled={loading}
                type="submit"
                variant="success"
                className="m-1"
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-1"
                  />
                ) : (
                  <GoCheck className="mr-1" />
                )}
                {t("submit")}
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default NewMeeting;
