import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  ButtonGroup,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { GoSync } from "react-icons/go";
import { useTranslation, Trans } from "react-i18next";
import { Table } from "components";
import { formatDateTime, formatDateString, tomorrow } from "utils/date";
import Flatpickr from "react-flatpickr";
import { i18name } from "utils/people";
import { getMeetingsByDate } from "api";
import { sortByCheckin } from "utils/sort";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import { invitationHasHostAndGuest } from "utils/filter";
import { latestVisit } from "utils/visits";

/**
 * Page to show all the invitation created by all users (Only Guards and Admin have access).
 */
const Dashboard: React.FC = () => {
  const today = new Date();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [meetings, setMeetings] = useState<any[]>([]);
  const [date, setDate] = useState(today);

  const headers = [
    {
      Header: t("invitation.start"),
      accessor: "start",
    },
    {
      Header: t("invitation.end"),
      accessor: "end",
    },
    {
      Header: t("guest"),
      accessor: "guest",
    },
    {
      Header: t("host"),
      accessor: "host",
    },
    {
      Header: t("visit.checked_in"),
      accessor: "checkin",
      sortType: React.useMemo(() => sortByCheckin, []),
    },
    {
      Header: t("visit.gov_id.verification"),
      accessor: "govId",
    },
    {
      Header: t("visit.number_plate"),
      accessor: "numberPlate",
    },
    {
      Header: t("visit.parking_lot"),
      accessor: "parkingLot",
    },
    {
      Header: t("visit.access_card"),
      accessor: "accessCard",
    },
  ];

  useEffect(() => {
    // Parse data received from the API to be placed on the dashboard"s table.
    const parseMeetings = (invitations: IMeeting[]) => {
      return (
        invitations
          // Hotfix: filter out invitations that do not have at least one host and one guest
          .filter(invitationHasHostAndGuest)
          .map((meeting: IMeeting) => {
            const guestList: IParticipant[] = meeting.participants.filter(
              (participant: IParticipant) => participant.isGuest,
            );
            const host: IParticipant = meeting.participants.filter(
              (participant: IParticipant) => !participant.isGuest,
            )[0];
            if (guestList.length > 1) {
              //Multiple guests
              return {
                start: formatDateTime(meeting.start),
                end: formatDateTime(meeting.end),
                guest: t("multiple_guests"),
                host:
                  i18name(host, i18n.language) + " (" + host.department + ")",
                checkin: `${
                  guestList.filter(
                    (guest: IParticipant) => latestVisit(guest.visits)?.checkIn,
                  ).length
                }/${guestList.length}`,
                govId: `${
                  guestList.filter(
                    (guest: IParticipant) => latestVisit(guest.visits)?.govId,
                  ).length
                }/${guestList.length}`,
                numberPlate: `${
                  guestList.filter(
                    (guest: IParticipant) =>
                      latestVisit(guest.visits)?.numberPlate,
                  ).length
                }/${guestList.length}`,
                parkingLot: `${
                  guestList.filter(
                    (guest: IParticipant) =>
                      latestVisit(guest.visits)?.parkingLot,
                  ).length
                }/${guestList.length}`,
                accessCard: `${
                  guestList.filter(
                    (guest: IParticipant) =>
                      latestVisit(guest.visits)?.accessCard,
                  ).length
                }/${guestList.length}`,
                subRows: guestList.map((guest: IParticipant) => {
                  return {
                    meetingId: meeting.id,
                    guestId: guest.id,
                    secret: meeting.secret,
                    start: formatDateTime(meeting.start),
                    end: formatDateTime(meeting.end),
                    guest:
                      i18name(guest, i18n.language) +
                      " (" +
                      guest.company +
                      ")",
                    host:
                      i18name(host, i18n.language) +
                      " (" +
                      host.department +
                      ")",
                    checkin:
                      latestVisit(guest.visits)?.checkIn &&
                      latestVisit(guest.visits)?.checkIn !==
                        "0000-00-00 00:00:00"
                        ? formatDateTime(
                            latestVisit(guest.visits)?.checkIn || "",
                          )
                        : "X",
                    govId: latestVisit(guest.visits)?.govId
                      ? latestVisit(guest.visits)?.govId === "valid"
                        ? t("visit.gov_id.valid")
                        : t("visit.gov_id.invalid")
                      : "X",
                    numberPlate: latestVisit(guest.visits)?.numberPlate || "X",
                    parkingLot: latestVisit(guest.visits)?.parkingLot || "X",
                    accessCard: latestVisit(guest.visits)?.accessCard || "X",
                  };
                }),
              };
            } else {
              const guest = guestList[0]; //Only one guest
              return {
                meetingId: meeting.id,
                guestId: guest.id,
                secret: meeting.secret,
                start: formatDateTime(meeting.start),
                end: formatDateTime(meeting.end),
                guest: `${i18name(guest, i18n.language)} (${guest.company})`,
                host: `${i18name(host, i18n.language)} (${host.department})`,
                checkin:
                  latestVisit(guest.visits)?.checkIn &&
                  latestVisit(guest.visits)?.checkIn !== "0000-00-00 00:00:00"
                    ? formatDateTime(latestVisit(guest.visits)?.checkIn || "")
                    : "X",
                subRows: [],
                govId: latestVisit(guest.visits)?.govId
                  ? latestVisit(guest.visits)?.govId === "valid"
                    ? t("visit.gov_id.valid")
                    : t("visit.gov_id.invalid")
                  : "X",
                numberPlate: latestVisit(guest.visits)?.numberPlate || "X",
                parkingLot: latestVisit(guest.visits)?.parkingLot || "X",
                accessCard: latestVisit(guest.visits)?.accessCard || "X",
              };
            }
          })
      );
    };

    const fetchMeeting = async (date?: string) => {
      setLoading(true);
      const response = await getMeetingsByDate(date);
      if (response.success) {
        setMeetings(parseMeetings(response.data));
      } else {
        toast.error(t(response.error));
      }
      setLoading(false);
    };
    fetchMeeting(formatDateString(date));
  }, [date, t, i18n.language]);

  return (
    <Container className="mt-3">
      <Row>
        <Col
          sm="12"
          data-toggle="popover"
          title={t("popover.meeting_day.title")}
          data-placement="bottom"
          data-content={t("popover.meeting_day.content")}
        >
          <h4>{t("dashboard.page_title")}</h4>
          <p>
            <Trans i18nKey="dashboard.page_subtitle">
              On this page, the invitations of <strong /> are listed. Use your
              elevated access privileges with caution.
            </Trans>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            headers={headers}
            data={meetings}
            headerComponent={
              <>
                <Button
                  className="mr-1 mt-1"
                  variant="secondary"
                  onClick={() => setDate(new Date(date.getTime()))}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-1"
                    />
                  ) : (
                    <GoSync />
                  )}
                </Button>
                <ButtonGroup className="mr-1 mt-1">
                  <Button
                    onClick={() => {
                      const _date = new Date(date);
                      _date.setDate(date.getDate() - 1);
                      setDate(_date);
                    }}
                  >
                    {"<"}
                  </Button>
                  <Flatpickr
                    value={date}
                    options={{
                      locale: i18n.language === "ja" ? Japanese : "en",
                      dateFormat: "Y-m-d",
                    }}
                    onChange={(dt: Date[]) => setDate(dt[0])}
                    render={(_, ref) => {
                      return (
                        <Button
                          ref={(myRef: any) => {
                            if (myRef) ref(myRef);
                          }}
                        >
                          {formatDateString(date)}
                        </Button>
                      );
                    }}
                  />
                  <Button
                    onClick={() => {
                      const _date = new Date(date);
                      _date.setDate(date.getDate() + 1);
                      setDate(_date);
                    }}
                  >
                    {">"}
                  </Button>
                </ButtonGroup>
                <Button
                  className="mr-1 mt-1"
                  variant={
                    date.getDate() === today.getDate() ? "primary" : "secondary"
                  }
                  onClick={() => setDate(today)}
                >
                  {t("today")}
                </Button>
                <Button
                  className="mr-1 mt-1"
                  variant={
                    date.getDate() === tomorrow().getDate()
                      ? "primary"
                      : "secondary"
                  }
                  onClick={() => setDate(tomorrow())}
                >
                  {t("tomorrow")}
                </Button>
              </>
            }
            loading={loading}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
