import React, { useState } from "react";
import { Card, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { i18name } from "utils/people";
import { formatDateTime } from "utils/date";
import { approveMeeting } from "api";
import { toast } from "react-toastify";
import { latestVisit } from "utils/visits";

interface Props {
  meetingId: number;
  secret: string;
  guest: IParticipant;
  updateMeeting?: () => void;
}

/**
 * Box with the checkIn information about the guest
 */
const ApprovedBox: React.FC<Props> = ({
  guest,
  meetingId,
  secret,
  updateMeeting,
}) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  //Fetch update meeting
  const submitApproval = async (event: any) => {
    setLoading(true);
    const response = await approveMeeting(meetingId, guest.id || 0, secret);
    setLoading(false);
    if (response.success && updateMeeting) {
      toast.success(t("updated-successfully"));
      updateMeeting();
    } else {
      toast.error(t(response.error));
    }
  };

  if (latestVisit(guest.visits)?.checkIn) {
    return (
      <Card>
        <Card.Body>
          <Card.Title>{t("person.approve")}</Card.Title>
          <Card.Text>
            {t("visit.has_checked_in", {
              name: i18name(guest, i18n.language),
              time: formatDateTime(latestVisit(guest.visits)?.checkIn || ""),
            })}
          </Card.Text>
          <Card.Text>
            {!guest.approved && (
              <Button onClick={submitApproval} disabled={loading}>
                {t("person.approve")}
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="ml-1"
                  />
                )}
              </Button>
            )}
            {guest.approved && (
              <span>
                {t("person.approved_at", {
                  dateTime: formatDateTime(guest.approved),
                })}
              </span>
            )}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
  return <div />;
};

export default ApprovedBox;
