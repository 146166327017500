import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Row,
  Col,
  ButtonGroup,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GoMail, GoClippy, GoCheck } from "react-icons/go";
import { guestUrl } from "utils/meeting";
import { i18name } from "utils/people";
import moment from "moment";
import "moment/locale/en-sg";
import "moment/locale/ja";
import { latestVisit } from "utils/visits";

interface Props {
  meeting: IMeeting;
  guestId: number | undefined;
  interactedCallback: Function;
}

/**
 * Box with the links and email templates for the invitation.
 */
const InviteBox: React.FC<Props> = ({
  meeting,
  guestId,
  interactedCallback,
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const [mailOpenedJa, setMailOpenedJa] = useState(false);
  const [mailOpenedEn, setMailOpenedEn] = useState(false);
  const linkRef = useRef<any>(null);
  const guest = meeting.participants.filter(
    (p: IParticipant) => p.id === guestId,
  )[0];

  const interactionIndicators = [copied, mailOpenedJa, mailOpenedEn];
  useEffect(() => {
    interactionIndicators.some((indicator) => indicator) &&
      interactedCallback();
    // eslint-disable-next-line
  }, interactionIndicators);

  const writeEmail = (lng: string) => {
    if (lng === "ja") setMailOpenedJa(true);
    if (lng === "en") setMailOpenedEn(true);
    const subject = t("invitation.email_subject", { lng });
    const body = `${t("invitation_guest.greeting", {
      name: i18name(guest, lng),
      lng,
    })} \
        \n\r${t("invitation_guest.qrcode_please", { lng })}\n${guestUrl(
          meeting,
          guestId,
        )}&lng=${lng} \
        \r${t("invitation_guest.validity", {
          from: moment(meeting.start)
            .locale(lng === "ja" ? "ja" : "en-sg")
            .format("LLLL"),
          to: moment(meeting.end)
            .locale(lng === "ja" ? "ja" : "en-sg")
            .format("LLLL"),
          lng,
        })}\
        \n\r${t("invitation_guest.id_please", {
          lng,
        })}\n\r${t("invitation_guest.thank_you", { lng })}`;
    window.open(
      `mailto:${encodeURIComponent(guest.email)}?subject=${encodeURIComponent(
        subject,
      )}&body=${encodeURIComponent(body)}`,
    );
  };

  if (latestVisit(guest.visits)?.checkIn) {
    return <div />;
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>{t("person.invite")}</Card.Title>
        <Row>
          <Col>
            <ButtonGroup style={{ width: "100%" }}>
              <Button
                variant={mailOpenedEn ? "success" : "primary"}
                onClick={() => writeEmail("en")}
              >
                <GoMail className="mr-1" />
                {mailOpenedEn ? t("invitation.email_opened") : t("english")}
              </Button>
              <Button
                variant={mailOpenedJa ? "success" : "primary"}
                onClick={() => writeEmail("ja")}
              >
                <GoMail className="mr-1" />
                {mailOpenedJa ? t("invitation.email_opened") : t("japanese")}
              </Button>
            </ButtonGroup>
          </Col>
          <Col>
            <InputGroup>
              <FormControl
                ref={linkRef}
                onClick={(event: any) => event.target.select()}
                type="text"
                value={guestUrl(meeting, guestId)}
                readOnly
              />
              <InputGroup.Append>
                <Button
                  variant={copied ? "success" : "secondary"}
                  onClick={() => {
                    if (linkRef.current) {
                      linkRef.current.select();
                      document.execCommand("Copy");
                      setCopied(true);
                    }
                  }}
                >
                  {copied ? (
                    <GoCheck className="mr-1" />
                  ) : (
                    <GoClippy className="mr-1" />
                  )}
                  {copied ? t("invitation.copied_url") : t("person.copy_url")}
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InviteBox;
