import React from "react";
import { createRoot } from "react-dom/client";
import App from "App";
import "bootstrap/dist/css/bootstrap.min.css";
import "flatpickr/dist/themes/airbnb.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import "translations/i18n";
import * as serviceWorker from "./serviceWorker";
import pkg from "../package.json";

console.log(pkg.name, pkg.version);
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
