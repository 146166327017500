import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import { getLocations } from "api";
import Select from "react-select";
import moment from "moment";

interface Props {
  meeting: IMeeting;
  onChange: (meetingChanged: IMeeting) => void;
  editMode: boolean;
}

interface Location {
  mapid: string;
  name: string;
  type: string;
}

/**
 * Form for general information about the meeting.
 */
const MeetingInfoForm: React.FC<Props> = ({ meeting, onChange, editMode }) => {
  const { t, i18n } = useTranslation();
  const todayMidnight = new Date();
  todayMidnight.setHours(0, 0, 0, 0);
  const [buildings, setBuildings] = useState<Location[]>([]);
  const [rooms, setRooms] = useState<Location[]>([]);
  const [mapApiError, setMapApiError] = useState(false);

  const fetchLocations = async function () {
    const response = await getLocations();
    if (response.success) {
      const buildings = response.data.locations
        .filter(
          (location: Location) =>
            location.type === "BUILDING" || location.type === "GATE",
        )
        .sort((a: Location, b: Location) => {
          if (a.mapid > b.mapid) return 1;
          if (a.mapid < b.mapid) return -1;
          return 0;
        });
      setBuildings([...buildings]);
      setRooms(
        response.data.locations.filter(
          (location: Location) => location.type === "ROOM",
        ),
      );
    } else {
      toast.error(t(response.error));
      setMapApiError(true);
    }
  };

  useEffect(() => {
    fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      editMode &&
      meeting.start &&
      (!meeting.end || moment(meeting.end).isSameOrBefore(meeting.start))
    ) {
      var datetime = new Date(meeting.start);
      datetime.setHours(datetime.getHours() + 1);
      onChange({ ...meeting, end: datetime.toISOString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, meeting.start]);

  return (
    <Row className="mb-2">
      <Col>
        <h4>{t("invitation.info")}</h4>
        <Row>
          <Col md="4">
            <Form.Group>
              <Form.Label>{t("invitation.building")}</Form.Label>
              {!mapApiError && editMode && (
                <Select
                  options={buildings}
                  isLoading={!buildings.length}
                  isClearable={true}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  isDisabled={!editMode}
                  placeholder="Building 20 (Product Center)"
                  value={{ name: meeting.building || "", mapid: "", type: "" }}
                  onChange={(location) => {
                    onChange({
                      ...meeting,
                      building: location ? (location as Location).name : "",
                    });
                  }}
                />
              )}
              {(mapApiError || !editMode) && (
                <input
                  disabled={!editMode}
                  className="form-control"
                  placeholder="Building 20 (Product Center)"
                  value={meeting.building || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange({ ...meeting, building: e.target.value })
                  }
                />
              )}
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>{t("invitation.room")}</Form.Label>
              <input
                disabled={!editMode}
                list="rooms"
                className="form-control"
                placeholder="Super Great"
                value={meeting.room || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange({ ...meeting, room: e.target.value })
                }
              />
              <datalist id="rooms">
                {rooms.sort &&
                  rooms
                    .sort()
                    .map((room: Location) => (
                      <option key={room.mapid}>{room.name}</option>
                    ))}
              </datalist>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Form.Group>
              <Form.Label>{t("invitation.start")}</Form.Label>
              <Flatpickr
                required
                disabled={!editMode}
                className="form-control"
                data-enable-time
                placeholder="YYYY-MM-DD HH:MM"
                value={meeting.start ? new Date(meeting.start) : undefined}
                options={{
                  locale: i18n.language === "ja" ? Japanese : "en",
                  dateFormat: "Y-m-d H:i",
                  allowInput: true,
                  minDate: meeting.start
                    ? moment(meeting.start).isSameOrAfter(moment(), "day")
                      ? todayMidnight // any date from today if start is defined and in the present or future
                      : meeting.start // any date from the existing start date if start is defined and in the past
                    : todayMidnight, // any date from today if no start date is defined
                  time_24hr: true,
                }}
                onChange={(date: Date[]) =>
                  onChange({ ...meeting, start: date[0].toISOString() })
                }
              />
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>{t("invitation.end")}</Form.Label>
              <Flatpickr
                required
                disabled={!editMode}
                className="form-control"
                data-enable-time
                placeholder="YYYY-MM-DD HH:MM"
                value={meeting.end ? new Date(meeting.end) : undefined}
                options={{
                  minDate: meeting.start ? new Date(meeting.start) : new Date(),
                  locale: i18n.language === "ja" ? Japanese : "en",
                  dateFormat: "Y-m-d H:i",
                  allowInput: true,
                  time_24hr: true,
                }}
                onChange={(date: Date[]) =>
                  onChange({ ...meeting, end: date[0].toISOString() })
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MeetingInfoForm;
