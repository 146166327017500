import { oneLine } from "common-tags";

/**
 * Returns JS Date object for tomorrow
 */
export function tomorrow(): Date {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
}
/**
 * Returns YYYY-MM-DD format
 */
export function formatDateString(date: Date): string {
  const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  const iso = new Date(date.getTime() - tzoffset).toISOString().slice(0, -1);
  return iso.split("T")[0];
}

/**
 * Returns YYYY-MM-DD HH:mm
 */
export function formatDateTime(date: string | number | Date) {
  const dt = new Date(date);
  return oneLine`
    ${dt.getFullYear().toString().padStart(4, "0")}-${(dt.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}
        ${dt.getHours().toString().padStart(2, "0")}:${dt
          .getMinutes()
          .toString()
          .padStart(2, "0")}`;
}
