import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "context/UserContext";
import Header from "components/Header";
import Footer from "components/Footer";
import Dashboard from "views/Dashboard";
import Invitations from "views/Invitations";
import NewMeeting from "views/NewMeeting";
import ShowMeeting from "views/Meeting";
import PrivateRoute from "routes/PrivateRoute";
import InvitationGuest from "views/InvitationGuest";
import Profile from "views/Profile";
import Login from "views/Login";
import Devices from "views/Devices";
import PrivacyPolicy from "views/PrivacyPolicy";
import CookiePolicy from "views/CookiePolicy";
import Statistics from "views/Statistics";

toast.configure({
  position: "top-right",
  autoClose: 5000,
  draggable: false,
  closeButton: false,
  closeOnClick: true,
  hideProgressBar: true,
});

function App() {
  return (
    <UserContext>
      <Router basename={process.env.PUBLIC_URL}>
        <Header />
        <Switch>
          <PrivateRoute
            path="/new"
            component={NewMeeting}
            rolesPermitted={["admin", "guard", ""]}
          />
          <PrivateRoute
            exact
            path="/meeting/:id/"
            component={ShowMeeting}
            rolesPermitted={["admin", "guard", ""]}
          />
          <PrivateRoute
            path="/profile"
            component={Profile}
            rolesPermitted={["admin", "guard", ""]}
          />
          <PrivateRoute
            path="/dashboard"
            component={Dashboard}
            rolesPermitted={["admin", "guard"]}
          />
          <PrivateRoute
            path="/invitations"
            component={Invitations}
            rolesPermitted={["admin", "guard", ""]}
          />
          <PrivateRoute
            path="/devices"
            component={Devices}
            rolesPermitted={["admin"]}
          />
          <PrivateRoute
            path="/statistics"
            component={Statistics}
            rolesPermitted={["admin", "guard"]}
          />
          <Route
            exact
            path="/invitation/:id/guest/:guestId/"
            component={InvitationGuest}
          />
          <Route path="/login" component={Login} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/cookies" component={CookiePolicy} />
          <Route render={() => <Redirect to="/dashboard" />} />
        </Switch>
        <Footer />
      </Router>
    </UserContext>
  );
}

export default App;
