import { useEffect } from "react";
import Chance from "chance";

const chance = new Chance();

/**
 * Hook to generate a mock meeting
 */
const useMockMeeting = (
  meeting: IMeeting,
  setMeeting: (meeting: IMeeting) => void,
) => {
  useEffect(() => {
    const keyPressHandler = (e: KeyboardEvent) => {
      if (e.which === 82 && e.shiftKey && e.altKey) {
        setMeeting({
          ...meeting,
          room: "Super Great",
          building: "Building 131",
          start: new Date().toISOString(),
          participants: meeting.participants.map((p: IParticipant) => {
            if (!p.isGuest) return p;
            const lastName = chance.last();
            const firstName = chance.first();
            return {
              lastName,
              firstName,
              tel: chance.phone(),
              company: chance.company(),
              department: chance.profession(),
              title: "",
              email: `${firstName}.${lastName}@${chance.domain()}`,
              isGuest: true,
            };
          }),
        });
      }
    };

    document.addEventListener("keydown", keyPressHandler);
    return () => {
      document.removeEventListener("keydown", keyPressHandler);
    };
  });
};

export default useMockMeeting;
