import React, { useEffect } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useExpanded,
  Row,
  Cell,
} from "react-table";
import {
  GoChevronDown,
  GoOrganization,
  GoChevronUp,
  GoPerson,
} from "react-icons/go";
import { Table } from "react-bootstrap";
import GlobalFilter from "components/CustomTable/FilterInputField";
import { useTranslation } from "react-i18next";
import Spinner from "components/CustomTable/Spinner";
import { useHistory } from "react-router-dom";

interface ICustomTableProps {
  data: any;
  headers: any;
  loading: boolean;
  headerComponent: React.ReactNode;
}
/**
 * Custom table for invitations
 */
const CustomTable: React.FC<ICustomTableProps> = ({
  loading,
  data,
  headers,
  headerComponent,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const columns = React.useMemo(
    () => [
      {
        Header: () => t("invitation.group"),
        id: "expander",
        minWidth: 500,
        Cell: ({ row }: { row: Row }) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? (
                <div style={{ whiteSpace: "nowrap" }}>
                  <GoOrganization />
                  <GoChevronUp className="ml-2" />
                </div>
              ) : (
                <div style={{ whiteSpace: "nowrap" }}>
                  <GoOrganization />
                  <GoChevronDown className="ml-2" />
                </div>
              )}
            </span>
          ) : row.depth === 0 ? (
            <GoPerson />
          ) : (
            <GoPerson className="ml-4" />
          ),
      },
      ...headers,
    ],
    [headers, t],
  );

  const globalFilter = (
    rows: Row<object>[],
    columnIds: string[],
    filterValue: any,
  ) => {
    const filterRow = (row: Row<object>, filterValue: any) => {
      return Object.values(row.values).some((rowValue: any) => {
        return rowValue !== undefined
          ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
          : false;
      });
    };

    return rows.filter((row: Row<object>) => {
      if (row.subRows.length > 0) {
        return (
          filterRow(row, filterValue) ||
          row.subRows.some((row: Row<object>) => filterRow(row, filterValue))
        );
      }
      return filterRow(row, filterValue);
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    state,
    prepareRow,
    allColumns,
    setGlobalFilter,
    rows,
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns,
      data,
      globalFilter,
      initialState: { sortBy: [{ id: "checkin", desc: true }] },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
  );

  // expand all rows by default
  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [toggleAllRowsExpanded, data]);

  return (
    <>
      <Table responsive {...getTableProps()} className="table-hover">
        <thead>
          <tr className="bg-light">
            <th colSpan={allColumns.length - 2}>{headerComponent}</th>
            <th colSpan={2} style={{ textAlign: "right" }}>
              <GlobalFilter
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {!loading && (
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => {
                    if (row.canExpand) {
                      row.toggleRowExpanded(!row.isExpanded);
                    } else {
                      history.push({
                        pathname: `meeting/${row.original.meetingId}`,
                        search: `?secret=${row.original.secret}`,
                        hash: `#${row.original.guestId}`,
                      });
                    }
                  }}
                >
                  {row.cells.map((cell: Cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
      {loading && <Spinner />}
    </>
  );
};

export default CustomTable;
