export function invitationHasHostAndGuest(invitation: IMeeting) {
  let hasHost = false;
  let hasGuest = false;
  invitation.participants.forEach((participant) => {
    if (participant.isGuest) {
      hasGuest = true;
    } else {
      hasHost = true;
    }
  });
  if (hasHost && hasGuest) return invitation;
  return null;
}
