import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { removeGuestByIndex, updateGuest, getGuests } from "utils/meeting";
import ParticipantInfo from "views/partials/ParticipantInfoForm";
import { InviteBox, ApprovedBox, GuardsBox } from "components";
import { GoX } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { Prompt, useLocation } from "react-router";
import { latestVisit } from "utils/visits";

const inlineStyle = {
  display: "inline-block",
  verticalAlign: "middle",
  margin: "10px 0",
};

interface Props {
  meeting: IMeeting;
  onChange: (meetingChanged: IMeeting) => void;
  scrollToGuestIndex?: string | undefined;
  editMode?: boolean;
  updateMeeting?: () => void;
}

/**
 * Form for all the guests in a meeting.
 */
const GuestInfoForm: React.FC<Props> = ({
  meeting,
  onChange,
  scrollToGuestIndex,
  updateMeeting,
  editMode = true,
}) => {
  const { t } = useTranslation();
  const guests = getGuests(meeting);
  const guestRef = useRef<any>(null);
  const [invitedGuests, setInvitedGuests] = useState<Set<IParticipant>>(
    new Set(),
  );
  const location = useLocation();

  // Scroll to the selected guest.
  useLayoutEffect(() => {
    if (guestRef.current) {
      guestRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestRef.current]);

  // notify the user if it looks like this is a new meeting and they might not have sent out all the invitations
  const remindRef = useRef(true);
  const remindToSend =
    !editMode &&
    location.hash === "" && // most links except newly created invitations contain a hash to quickly scroll to a guest
    invitedGuests.size < guests.length;
  remindRef.current = remindToSend;
  const leaveMessage = t("invitation.send_reminder");
  const onUnload = function (event: Event) {
    if (remindRef.current) {
      event.preventDefault();
      // @ts-ignore Chrome not following standard https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#example
      event.returnValue = "";
      return leaveMessage;
    }
  };
  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Row>
      <Col>
        <h4>{t("invitation.guest_info")}</h4>
        {guests.map((participant: IParticipant, index: number) => (
          <Row
            key={`row-${index}`}
            ref={
              parseInt(scrollToGuestIndex || "-1") === participant.id
                ? guestRef
                : null
            }
          >
            <Col>
              {index > 0 &&
                editMode &&
                !latestVisit(participant.visits)?.checkIn && (
                  <Button
                    className="mr-2"
                    style={inlineStyle}
                    variant="secondary"
                    onClick={() => onChange(removeGuestByIndex(meeting, index))}
                  >
                    <GoX />
                  </Button>
                )}
              <h5 style={inlineStyle}>{`${t("guest")} #${index + 1}`}</h5>
              <ParticipantInfo
                disabled={
                  !editMode || !!latestVisit(participant.visits)?.checkIn
                }
                onChangeParticipant={(guest: IParticipant) =>
                  onChange(updateGuest(meeting, guest, index))
                }
                participant={participant}
              />
              {!editMode && (
                <>
                  <InviteBox
                    guestId={participant.id}
                    meeting={meeting}
                    interactedCallback={() => {
                      const set = new Set(invitedGuests);
                      set.add(participant);
                      setInvitedGuests(set);
                    }}
                  />
                  <ApprovedBox
                    guest={participant}
                    meetingId={meeting.id || 0}
                    secret={meeting.secret || ""}
                    updateMeeting={updateMeeting}
                  />
                  <GuardsBox
                    guest={participant}
                    onChangeGuest={(guest: IParticipant) =>
                      onChange(updateGuest(meeting, guest, index))
                    }
                  />
                </>
              )}
            </Col>
          </Row>
        ))}
      </Col>
      <Prompt when={remindToSend} message={leaveMessage} />
    </Row>
  );
};

export default GuestInfoForm;
