import React from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";

const CustomSpinner: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col className="text-center">
          <Spinner animation="border" role="status" className="mb-3">
            <span className="sr-only" />
          </Spinner>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomSpinner;
