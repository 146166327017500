/**
 * Get first non guest from a Meeting.
 */
export function getHost(meeting: IMeeting): IParticipant {
  if (meeting.participants) {
    return meeting.participants.filter((p: IParticipant) => !p.isGuest)[0];
  }
  return {} as IParticipant;
}
/**
 * Get list of guests from a Meeting.
 */
export function getGuests(meeting: IMeeting): IParticipant[] {
  if (meeting.participants) {
    return meeting.participants.filter((p: IParticipant) => p.isGuest);
  }
  return [];
}
/**
 * Get guest in a meeting by ID.
 */
export function getGuestbyId(
  meeting: IMeeting,
  id: string,
): IParticipant | null {
  if (meeting.participants) {
    return (
      meeting.participants.find((p: IParticipant) => p.id === parseInt(id)) ||
      null
    );
  }
  return null;
}
/**
 * Remove one guest from a meeting by index.
 */
export function removeGuestByIndex(meeting: IMeeting, index: number): IMeeting {
  const host = getHost(meeting);
  const guests = getGuests(meeting);
  const guestsUpdated = guests.filter(
    (e: IParticipant, i: number) => index !== i,
  );
  return {
    ...meeting,
    participants: [host, ...guestsUpdated],
  };
}

/**
 * Update Guest information in a meeting.
 */
export function updateGuest(
  meeting: IMeeting,
  guest: IParticipant,
  index: number,
): IMeeting {
  const host = getHost(meeting);
  const guests = getGuests(meeting);
  const guestsUpdated = Object.assign([...guests], { [index]: guest });
  return {
    ...meeting,
    participants: [host, ...guestsUpdated],
  };
}

/**
 * Add new guest into meeting.
 */
export function addNewGuest(meeting: IMeeting): IMeeting {
  const newGuest = {
    title: "",
    firstName: "",
    lastName: "",
    company: "",
    department: "",
    email: "",
    tel: "",
    isGuest: true,
  };
  return {
    ...meeting,
    participants: [...meeting.participants, newGuest],
  };
}
/**
 * Get guest invitation URL (URL with the QRcode).
 */
export function guestUrl(meeting: IMeeting, guestId: number | undefined) {
  if (meeting.id && meeting.secret && guestId) {
    return `${window.location.origin}/invitation/${meeting.id}/guest/${guestId}/?secret=${meeting.secret}`;
  }
  return "";
}
