import { defaultErrorResponse } from "utils/error";

/**
 * Delete a meeting.
 */
export default async function deleteMeeting(
  meetingId: number,
): Promise<IResponse> {
  try {
    const response = await fetch("/api/meeting/delete", {
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ id: meetingId }),
    });
    if (response.status === 400) {
      return {
        success: false,
        error: "guest-has-checked-in",
      };
    } else if (response.status === 202) {
      return {
        success: true,
        error: "",
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      redirect: null,
      success: false,
      error: "api-error",
    };
  }
}
