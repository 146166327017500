import React from "react";
import { Card, Row, Col, Form, Button, Accordion } from "react-bootstrap";
import { formatDateTime } from "utils/date";
import { GoCheck, GoX } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { useContextUser } from "context/UserContext";

interface Props {
  guest: IParticipant;
  onChangeGuest: (guest: IParticipant) => void;
}

/**
 * Box used for fill information about the guest by the guards
 */
const GuardsBox: React.FC<Props> = ({ guest, onChangeGuest }) => {
  const { t } = useTranslation();
  const [user] = useContextUser();

  if ((user.role !== "guard" && user.role !== "admin") || !guest.visits) {
    return <div />;
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>{t("visit.records")}</Card.Title>
        <Accordion defaultActiveKey={(guest.visits.length - 1 || 0) + ""}>
          {guest.visits
            .sort((a, b) => (a.id || 0) - (b.id || 0))
            .map((visit, index) => {
              // define bg colors
              const overallBg =
                visit.govId === "invalid"
                  ? "bg-danger"
                  : visit.govId === "valid" && guest.approved && visit.checkIn
                    ? "bg-success"
                    : "bg-warning";
              return (
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={index + ""}
                    style={{ cursor: "pointer" }}
                    className={`${overallBg}`}
                  >
                    {visit.checkIn
                      ? formatDateTime(new Date(visit.checkIn))
                      : t("visit.not_checked_in")}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index + ""}>
                    <Card.Body className={`${overallBg} pt-0 pb-0`}>
                      <Row>
                        <Col md="6">
                          <p
                            className={`p-2 rounded ${
                              visit.checkIn ? "bg-success" : "bg-warning"
                            }`}
                          >
                            {visit.checkIn && (
                              <>
                                <GoCheck className="mr-1" />
                                {t("visit.checked_in_at", {
                                  dateTime: formatDateTime(visit.checkIn || ""),
                                })}
                              </>
                            )}
                            {!visit.checkIn && (
                              <>
                                <GoX className="mr-1" />
                                {t("visit.not_checked_in")}
                              </>
                            )}
                          </p>
                        </Col>
                        <Col md="6">
                          <p
                            className={`p-2 rounded ${
                              guest.approved ? "bg-success" : "bg-warning"
                            }`}
                          >
                            {guest.approved && (
                              <>
                                <GoCheck className="mr-1" />
                                {t("person.approved_at", {
                                  dateTime: formatDateTime(guest.approved),
                                })}
                              </>
                            )}
                            {!guest.approved && (
                              <>
                                <GoX className="mr-1" />
                                {t("person.not_approved")}
                              </>
                            )}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" lg="3">
                          <Form.Group>
                            <Form.Label>
                              {t("visit.gov_id.verification")}
                            </Form.Label>
                            <Form.Control
                              as="select"
                              value={visit.govId || ""}
                              onChange={(
                                e: React.ChangeEvent<HTMLSelectElement>,
                              ) => {
                                visit.govId = e.target.value;
                                const visits = guest.visits?.map((_visit) =>
                                  _visit.id === visit.id ? visit : _visit,
                                );
                                guest.visits = visits;
                                onChangeGuest({ ...guest });
                              }}
                            >
                              <option value=""></option>
                              <option value="valid">
                                {t("visit.gov_id.valid")}
                              </option>
                              <option value="invalid">
                                {t("visit.gov_id.invalid")}
                              </option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="6" lg="3">
                          <Form.Group>
                            <Form.Label>{t("visit.access_card")}</Form.Label>
                            <Form.Control
                              type="text"
                              value={visit.accessCard || ""}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                visit.accessCard = e.target.value;
                                const visits = guest.visits?.map((_visit) =>
                                  _visit.id === visit.id ? visit : _visit,
                                );
                                guest.visits = visits;
                                onChangeGuest({ ...guest });
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="6" lg="3">
                          <Form.Group>
                            <Form.Label>{t("visit.number_plate")}</Form.Label>
                            <Form.Control
                              type="text"
                              value={visit.numberPlate || ""}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                visit.numberPlate = e.target.value;
                                const visits = guest.visits?.map((_visit) =>
                                  _visit.id === visit.id ? visit : _visit,
                                );
                                guest.visits = visits;
                                onChangeGuest({ ...guest });
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="6" lg="3">
                          <Form.Group>
                            <Form.Label>{t("visit.parking_lot")}</Form.Label>
                            <Form.Control
                              type="text"
                              value={visit.parkingLot || ""}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                visit.parkingLot = e.target.value;
                                const visits = guest.visits?.map((_visit) =>
                                  _visit.id === visit.id ? visit : _visit,
                                );
                                guest.visits = visits;
                                onChangeGuest({ ...guest });
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
        </Accordion>
        <Row className="pt-1">
          <Col md="12">
            <Form.Group>
              <Form.Label>{t("person.note")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                value={guest.guardNote || ""}
                placeholder={t("person.note")}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  onChangeGuest({ ...guest, guardNote: e.target.value })
                }
                maxLength={1000}
              />
              <Form.Text>
                {guest.guardNote?.length || 0}/{1000} {t("characters")}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="12">
            <Button block type="submit" variant="dark">
              {t("update")}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GuardsBox;
