import React, { useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GoPencil } from "react-icons/go";
import ParticipantInfo from "views/partials/ParticipantInfoForm";
import { getGuests, getHost } from "utils/meeting";

interface Props {
  meeting: IMeeting;
  onChange: (meetingChanged: IMeeting) => void;
  editMode: boolean;
}

/**
 * Form for the host of the meeting.
 */
const HostInfoForm: React.FC<Props> = ({ meeting, onChange, editMode }) => {
  const { t } = useTranslation();
  const [hostInfoOpen, setHostInfoOpen] = useState(!editMode);
  const guests: IParticipant[] = getGuests(meeting);
  const host: IParticipant = getHost(meeting);

  if (!hostInfoOpen) {
    return (
      <Row className="mb-2">
        <Col>
          <h4>{t("invitation.host_info")}</h4>
          {
            <Button
              variant="info"
              className="m-1"
              onClick={() => setHostInfoOpen(true)}
            >
              <GoPencil className="mr-1" />
              {t("edit")}
            </Button>
          }
          <span className="text-muted">{t("invitation.host_info_detail")}</span>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="mb-2">
      <Col>
        <h4>{t("invitation.host_info")}</h4>
        <ParticipantInfo
          disabled={!editMode}
          onChangeParticipant={(p: IParticipant) =>
            onChange({
              ...meeting,
              participants: [p, ...guests],
            })
          }
          participant={host}
        />
      </Col>
    </Row>
  );
};

export default HostInfoForm;
