import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IFilterInputFieldProps {
  globalFilter: any;
  setGlobalFilter: (filterValue: any) => void;
}

/**
 * UI for filtering
 */
const FilterInputField: React.FC<IFilterInputFieldProps> = ({
  globalFilter,
  setGlobalFilter,
}) => {
  const { t } = useTranslation();
  return (
    <Form.Control
      className="m-1"
      size="sm"
      type="text"
      placeholder={t("search")}
      value={globalFilter || ""}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
};

export default FilterInputField;
