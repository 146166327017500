import React, { useState, createContext, useContext } from "react";

export const UserContainer = createContext<any>([
  {} as IUser,
  (user: IUser) => {},
]);
export const useContextUser = () => useContext(UserContainer);

interface IUserContextProps {
  children: React.ReactNode;
}
/**
 * Context provider user information
 */
export const UserContext: React.FC<IUserContextProps> = ({ children }) => {
  const [user, setUser] = useState({} as IUser);

  return (
    <UserContainer.Provider value={[user, setUser]}>
      {children}
    </UserContainer.Provider>
  );
};
