import React from "react";
import { Container, Navbar, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GoCommentDiscussion, GoDeviceMobile, GoFile } from "react-icons/go";
import { useContextUser } from "context/UserContext";
import { useHistory, Link } from "react-router-dom";

/**
 * Application footer
 */
const Footer: React.FC = () => {
  const { t } = useTranslation();
  const [user] = useContextUser();
  const isLoggedIn = !!user.id;
  const history = useHistory();

  return (
    <Navbar>
      <Container>
        <Row style={{ width: "100%" }}>
          <Col md="12">
            <hr />
          </Col>
          <Col md="6">
            <GoCommentDiscussion />{" "}
            <a
              href="https://social.cloud.tbintra.net/groups/dta-reception/"
              target="_blank"
              rel="noreferrer"
            >
              {t("social_intranet")}
            </a>
          </Col>
          <Col md="4">
            <GoFile /> <Link to="/privacy">{t("privacy-policy")}</Link> |{" "}
            <Link to="/cookies">{t("cookie-policy")}</Link>
          </Col>
          <Col md="2" className="d-flex justify-content-md-end">
            {isLoggedIn && user.role === "admin" && (
              <div
                onClick={() => history.push("/devices")}
                style={{ cursor: "pointer" }}
              >
                <GoDeviceMobile />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Footer;
