import { getVisitors } from "api/getStatistics";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Table,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Flatpickr from "react-flatpickr";
import MonthSelect from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { Japanese } from "flatpickr/dist/l10n/ja.js";

export default function Statistics() {
  const { t, i18n } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [statisticsByDate, setStatisticsByDate] = useState<
    IVisitorStatistics[]
  >([]);
  const [statisticsByHour, setStatisticsByHour] = useState<
    IVisitorStatistics[]
  >([]);
  const [date, setDate] = useState(new Date());

  async function fetchStatistics() {
    setLoading(true);
    const from = moment(date).startOf("month").format("YYYY-MM-DD");
    const to = moment(date)
      .add(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    const byDateResponse = await getVisitors(from, to, "date");
    if (byDateResponse.success) {
      setStatisticsByDate(byDateResponse.data);
    } else {
      toast.error(t(byDateResponse.error));
    }
    const byHourResponse = await getVisitors(from, to, "hour");
    if (byHourResponse.success) {
      setStatisticsByHour(byHourResponse.data);
    } else {
      toast.error(t(byHourResponse.error));
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchStatistics();
    // eslint-disable-next-line
  }, [date]);

  return (
    <Container className="mt-3">
      <Row>
        <Col sm="12">
          <h4>{t("statistics.page_title")}</h4>
          <ButtonGroup className="mr-1 mt-1">
            <Button
              onClick={() => {
                setDate(moment(date).subtract(1, "month").toDate());
              }}
            >
              {"<"}
            </Button>
            <Flatpickr
              value={date}
              options={{
                locale: i18n.language === "ja" ? Japanese : "en",
                dateFormat: "Y-m",
                plugins: [
                  // @ts-ignore
                  new MonthSelect({
                    dateFormat: "Y-m",
                  }),
                ],
              }}
              onChange={(dt: Date[]) => setDate(dt[0])}
              render={(_, ref) => {
                return (
                  <Button
                    ref={(myRef: any) => {
                      if (myRef) ref(myRef);
                    }}
                  >
                    {moment(date).format("YYYY-MM")}
                  </Button>
                );
              }}
            />
            <Button
              onClick={() => {
                setDate(moment(date).add(1, "month").toDate());
              }}
            >
              {">"}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="12">
          <h5>{t("statistics.by_date")}</h5>
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-1"
            />
          )}
          {!isLoading && !statisticsByDate.length && t("statistics.no_data")}
          {!isLoading && !!statisticsByDate.length && (
            <Table responsive>
              <thead>
                <tr>
                  {Object.keys(statisticsByDate[0]).map((attribute) => (
                    <th key={attribute}>{t("statistics." + attribute)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {statisticsByDate.map((visitor) => (
                  <tr key={visitor.date}>
                    {Object.values(visitor).map((value) => (
                      <td>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="12">
          <h5>{t("statistics.by_hour")}</h5>
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-1"
            />
          )}
          {!isLoading && !statisticsByHour.length && t("statistics.no_data")}
          {!isLoading && !!statisticsByHour.length && (
            <Table responsive>
              <thead>
                <tr>
                  {Object.keys(statisticsByHour[0]).map((attribute) => (
                    <th key={attribute}>{t("statistics." + attribute)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {statisticsByHour.map((checkIn) => (
                  <tr key={checkIn.hour}>
                    {Object.values(checkIn).map((value) => (
                      <td>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </Container>
  );
}
