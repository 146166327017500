import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import queryString from "query-string";
import { getUser } from "api";
import { useContextUser } from "context/UserContext";
import { useLocation, useHistory } from "react-router-dom";

/**
 * Login page
 */
const Login: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [, setUser] = useContextUser();
  const parsed = queryString.parse(location.search);
  const redirect = !Array.isArray(parsed.redirect) ? parsed.redirect || "" : "";
  const search = !Array.isArray(parsed.search) ? parsed.search || "" : "";
  const hash = !Array.isArray(parsed.hash) ? parsed.hash || "" : "";

  // Try to load user
  useEffect(() => {
    // Check if user is logged
    const fetchUser = async () => {
      const response = await getUser();
      if (response.success) {
        setUser(response.data);
        const redirectAfterLogin = localStorage.getItem("redirect");
        if (redirectAfterLogin) {
          // Redirect right after the user has logged in
          localStorage.removeItem("redirect");
          history.replace(redirectAfterLogin);
        } else if (redirect) {
          // Redirect if user was already logged in
          history.replace({ pathname: redirect, search: search, hash: hash });
        } else {
          //There is no explicit redirection
          history.push("/");
        }
      } else {
        // If not logged in, redirect to back end login
        if (redirect)
          localStorage.setItem(
            "redirect",
            `${redirect}${search || ""}${hash || ""}`,
          );
        window.location.href = "/sso";
      }
    };
    fetchUser();
  }, [hash, history, redirect, search, setUser]);

  return (
    <div className="text-center mt-3">
      <Spinner animation="border" />
    </div>
  );
};

export default Login;
