import { Row as IRow } from "react-table";

/**
 * Sort rows on react table according to the most recent check-in time in the meeting.
 */
export function sortByCheckin(a: IRow, b: IRow): number {
  let a_date = a.values.checkin
    ? a.values.checkin !== "X"
      ? new Date(a.values.checkin).getTime()
      : 0
    : 0;
  let b_date = b.values.checkin
    ? b.values.checkin !== "X"
      ? new Date(b.values.checkin).getTime()
      : 0
    : 0;
  if (a.subRows.length > 0) {
    a_date = a.subRows.reduce((acc: number, row: IRow) => {
      if (a.values.checkin === "X") {
        return acc;
      }
      return new Date(row.values.checkin).getTime() > acc
        ? new Date(row.values.checkin).getTime()
        : acc;
    }, 0);
  }
  if (b.subRows.length > 0) {
    b_date = b.subRows.reduce((acc: number, row: IRow) => {
      if (b.values.checkin === "X") {
        return acc;
      }
      return new Date(row.values.checkin).getTime() > acc
        ? new Date(row.values.checkin).getTime()
        : acc;
    }, 0);
  }
  return a_date - b_date;
}
