import { defaultErrorResponse } from "utils/error";

/**
 * Get locations
 */
export default async function getLocations(): Promise<IResponse> {
  try {
    const response = await fetch("https://map.mitsubishi-fuso.com/graphql", {
      method: "POST",
      body: JSON.stringify({ query: "{ locations { mapid, name, type } }" }),
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 500) {
      return {
        success: false,
        error: "map-api-error",
      };
    } else if (response.status === 200) {
      const data = await response.json();
      return {
        success: true,
        error: "",
        data: data.data,
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      success: false,
      error: "map-api-error",
    };
  }
}
