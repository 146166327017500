import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function CookiePolicy() {
  const { t, i18n } = useTranslation();
  return (
    <Container className="mt-3">
      <h4>{t("cookie-policy")}</h4>
      {i18n.language === "ja" ? <CookiePolicyJa /> : <CookiePolicyEn />}
    </Container>
  );
}

function CookiePolicyEn() {
  return (
    <div>
      <h5>Cookies</h5>
      <p>
        Mitsubishi Fuso uses cookies to record the preferences of visitors, to
        enable us to optimize the design of our web site. Cookies are small
        files which are stored on your hard drive. They ease navigation, and
        increase the user-friendliness of a web site. Cookies also help us to
        identify the most popular sections of our web site. This enables us to
        provide content that is more accurately suited to your needs, and in so
        doing improve our service. Cookies can be used to determine whether
        there has been any contact between us and your computer in the past.
        Only the cookie on your computer is identified. Personal details can be
        saved in cookies, provided that you have consented to this, for example,
        in order to facilitate secure online access so that you not need to
        enter your user ID and password again.
      </p>
      <p>
        If you choose not to accept cookies, you can still visit our website.
        Most browsers automatically accept cookies. You can prevent cookies from
        being stored on your hard drive by setting your browser to not accept
        cookies. The exact instructions for this can be found in the manual for
        your browser. You can delete cookies already on your hard drive at any
        time. However, if you choose not to accept cookies it may result in a
        reduced availability of the services provided by our web site.
      </p>
      <p hidden>
        For the purpose of statistical analysis Mitsubishi Fuso uses [insert
        provider and tools]. If you do not wish Mitsubishi Fuso to collect and
        analyze statistical data of your visit you may object for the future use
        of your data at any time (opt-out).
      </p>
      <p hidden>
        For the technical implementation of your objection it is necessary to
        install an opt-out cookie on your browser. This cookie will only
        indicate that you have opted-out. Please note that for technical reasons
        the opt-out cookie will only affect the browser you object from. If you
        delete the cookies in your browser or use a different device or browser
        respectively, you will need to opt-out again.
      </p>
      <p hidden>Please click here to install the opt-out cookie.</p>
    </div>
  );
}

function CookiePolicyJa() {
  return (
    <div>
      <h5>クッキー</h5>
      <p>
        三菱ふそうでは、当社のウェブサイトをご利用になるお客様の趣向を分析し、それに応じたウェブサイトの提供に努めています。クッキーとは、お客様のハードディスク上に保存されるファイルです。
        これにより特定のウェブサイト上での機能性と操作簡便性の向上を、ご利用者の皆様に提供しています。
        さらに、クッキーの使用により当社のウェブサイト上で特に人気のある領域を認識することも可能としています。
        このようにして、当社のウェブサイトでご提供する内容を改善し、お客様のニーズに的確に適合できるようにしています。
        また、すでにお客様のコンピューターから当社のウェブサイトへのアクセス有無を確認する目的でクッキーを使用することも可能です。
        お客様のコンピューター上ではクッキーのみが認識されます｡
        お客様からのご承認に応じて個人的情報がクッキー内に保存されることがありますが、これは例えばセキュリティー保護されているオンラインアクセスを簡素化するために使用され、これによりお客様のユーザーIDやパスワードの再入力が不要となります。
      </p>
      <p>
        もちろん、クッキーなしに当社のウェブサイトをご覧になることも可能です。ほとんどのブラウザではクッキーを自動的に受け入れる設定となっています。ブラウザ設定で「クッキーを受け入れない」を選択すると、クッキーがハードディスク上に保存されないようにすることができます。詳しくはご利用になっているブラウザのメーカーによる説明書をご参照ください。ご使用中のコンピューターに保存されているクッキーは、削除することが可能です。ただし、クッキーを受け入れないように設定された場合、当社のウェブサイト上での機能が一部制限されることがあり、閲覧に支障が出ることもあります。
      </p>
    </div>
  );
}
