/**
 * Gets the latest visit, checked in or not (necessary for legacy data)
 */
export function latestVisit(visits?: IVisit[]): IVisit | null {
  return (
    visits
      ?.sort((a, b) => {
        return (a.id || 0) - (b.id || 0);
      })
      .at(-1) || null
  );
}
