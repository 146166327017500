import { defaultErrorResponse } from "utils/error";

/**
 * Approve a guest in a meeting.
 */
export default async function approveMeeting(
  id: number,
  guestId: number,
  secret: string,
): Promise<IResponse> {
  try {
    const response = await fetch(
      `/api/meeting/${id}/guest/${guestId}/approve?secret=${secret}`,
      {
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
      },
    );
    if (response.status === 200) {
      return {
        success: true,
        error: "",
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      redirect: null,
      success: false,
      error: "api-error",
    };
  }
}
