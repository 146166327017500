import { defaultErrorResponse } from "utils/error";

/**
 * Create or update a meeting
 */
export default async function createUpdateMeeting(
  meeting: IMeeting,
): Promise<IResponse> {
  try {
    const response = await fetch("/api/meeting", {
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(meeting),
    });
    if (response.status === 400) {
      return {
        success: false,
        error: "invalid-meeting",
      };
    } else if (response.status === 201) {
      const data = await response.json();
      return {
        redirect: `meeting/${data.id}?secret=${data.secret}`,
        success: true,
        error: "",
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      redirect: null,
      success: false,
      error: "api-error",
    };
  }
}
