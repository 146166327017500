import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router";
import { useContextUser } from "context/UserContext";
import { isComplete } from "utils/people";

interface Props extends RouteProps {
  rolesPermitted: any[];
}

/**
 * Block route if not login or the role doesn't have the rights.
 */
export const PrivateRoute: React.FC<Props> = (props) => {
  const [user] = useContextUser();
  const location = useLocation();
  const logged = !!user.id;
  const userRole =
    user.role === null || user.role === undefined ? "" : user.role;

  //Not logged in
  if (!logged && location.pathname !== "/login") {
    return (
      <Redirect
        to={{
          pathname: "/login",
          search: `?redirect=${location.pathname}${
            location.search ? `&search=${location.search}` : ""
          }${location.hash ? `&hash=${location.hash}` : ""}`,
        }}
      />
    );
    //First login, incomplete profile.
  } else if (
    (userRole === "" || userRole === "admin") &&
    !isComplete(user) &&
    location.pathname !== "/profile"
  ) {
    localStorage.setItem(
      "redirect",
      `${location.pathname}${location.search || ""}${location.hash || ""}`,
    );
    return <Redirect to={{ pathname: "/profile" }} />;
    // Role permitted to access the page
  } else if (props.rolesPermitted.includes(userRole)) {
    return <Route {...props} />;
    //If role doesn't have rights to access the page
  } else {
    //Redirect depending on the role
    if (userRole === "guard") {
      return <Redirect to={{ pathname: "/dashboard" }} />;
    } else if (userRole === "" || userRole === "admin") {
      return <Redirect to={{ pathname: "/invitations" }} />;
    } else {
      return (
        <p style={{ textAlign: "center", marginTop: "2rem" }}>
          No Permission. User role unrecognized.
        </p>
      );
    }
  }
};

export default PrivateRoute;
