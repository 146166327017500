import { defaultErrorResponse } from "utils/error";

/**
 * Update user profile.
 */
export default async function updateUserProfile(
  user: IUser,
): Promise<IResponse> {
  try {
    const response = await fetch(`/api/users/${user.id}`, {
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(user),
    });
    if (response.status === 400) {
      return {
        success: false,
        error: "input-invalid",
      };
    } else if (response.status === 200) {
      const data = await response.json();
      return {
        success: true,
        error: "",
        data: data,
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      redirect: null,
      success: false,
      error: "api-error",
    };
  }
}
