import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Navbar, Nav, Dropdown, Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EnvironmentTag from "components/EnvironmentTag";
import { useContextUser } from "context/UserContext";
import { GoPerson, GoGlobe, GoQuestion } from "react-icons/go";
import fusoImage from "assets/images/fuso.svg";
import InfoModal from "components/InfoModal";

/**
 * Application Header
 */
const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [user] = useContextUser();
  const isLoggedIn = !!user.id;
  const [infoOpen, setInfoOpen] = useState(false);

  const isGuard = (user: any) => user.role === "guard";
  const isAdmin = (user: any) => user.role === "admin";

  const renderNavLinks = () => {
    if (!isLoggedIn) return <div />;
    return (
      <Nav variant="pills">
        <Nav.Link disabled hidden={!(isGuard(user) || isAdmin(user))}>
          {t("privileges.admin")}
        </Nav.Link>
        <Nav.Link
          hidden={!(isGuard(user) || isAdmin(user))}
          active={location.pathname === "/dashboard"}
          onClick={() => history.push("/dashboard")}
        >
          {t("dashboard.page_title")}
        </Nav.Link>
        <Nav.Link
          hidden={!(isGuard(user) || isAdmin(user))}
          active={location.pathname === "/statistics"}
          onClick={() => history.push("/statistics")}
        >
          {t("statistics.page_title")}
        </Nav.Link>
        <Nav.Link disabled hidden={!(isGuard(user) || isAdmin(user))}>
          {t("privileges.user")}
        </Nav.Link>
        <Nav.Link
          active={location.pathname === "/invitations"}
          onClick={() => history.push("/invitations")}
        >
          {t("invitations.short_title")}
        </Nav.Link>
        <Nav.Link
          active={location.pathname === "/new"}
          onClick={() => history.push("/new")}
        >
          {t("new_invitation")}
        </Nav.Link>
      </Nav>
    );
  };

  return (
    <div>
      <EnvironmentTag />
      <Navbar bg="light" expand="sm" sticky="top">
        <Container>
          <Navbar.Brand
            href="/"
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              history.push("/");
            }}
          >
            <img
              src={fusoImage}
              style={{ marginRight: "0.5rem" }}
              width="30"
              height="30"
              alt="Fuso Logo"
            />
            {t("app_name")}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse>{renderNavLinks()}</Navbar.Collapse>

          <Navbar.Collapse className="justify-content-end text-center">
            {isLoggedIn && (
              <Button
                active={location.pathname === "/profile"}
                className="btn-light"
                onClick={() => history.push("/profile")}
                title={t("profile.page_title")}
              >
                {`${user.id} `}
                <GoPerson />
              </Button>
            )}

            <Button
              className="btn-light"
              onClick={() => setInfoOpen(true)}
              title={t("info.general-information")}
            >
              <GoQuestion />
            </Button>

            <Dropdown
              style={{ whiteSpace: "normal" }}
              title={t("info.language")}
            >
              <Dropdown.Toggle className="btn-light" id="dropdown-basic">
                <GoGlobe />
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ minWidth: "fit-content" }}>
                <Dropdown.Item
                  onClick={() => i18n.changeLanguage("en")}
                  active={i18n.language === "en"}
                >
                  English
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => i18n.changeLanguage("ja")}
                  active={i18n.language === "ja"}
                >
                  日本語
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* <Button className="btn-light" onClick={()=>history.push("/login")}>
                            <GoSignOut}/>
                        </Button> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <InfoModal show={infoOpen} onHide={() => setInfoOpen(false)} />
    </div>
  );
};

export default Header;
