import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Table,
  Form,
  InputGroup,
  Button,
  Modal,
} from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { useContextUser } from "context/UserContext";
import { getDevices, createDevice } from "api";
import { toast } from "react-toastify";
import { GoCheck, GoX } from "react-icons/go";
import { formatDateTime } from "utils/date";

/**
 * Page to show all devices and offer a form to create new ones
 */
const Devices: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [user] = useContextUser();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState<IDeviceComplete[]>([]);
  const [device, setDevice] = useState<IDevice>({ name: "" });

  // When page loads, get device list
  useEffect(() => {
    // Fetch today meetings
    const call = async () => {
      setLoading(true);
      const response = await getDevices();
      if (response.success) {
        console.log(response.data);
        setDevices(response.data);
      } else {
        toast.error(t(response.error));
      }
      setLoading(false);
    };

    call();
  }, [t, user.id, i18n.language, device.secret]);

  const createDeviceHandler = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const response = await createDevice(device);
    if (response.success) {
      let credentials: IDeviceResponse = response.data;
      setDevice({ ...device, ...credentials });
    } else {
      toast.error(t(response.error));
    }
    setLoading(false);
  };

  const DeviceList = () => {
    if (loading)
      return (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="mr-1"
        />
      );
    return (
      <Table>
        <thead>
          <tr>
            <th>{t("devices.id")}</th>
            <th>{t("devices.name")}</th>
            <th>{t("devices.active")}</th>
            <th>{t("devices.lastSeen")}</th>
            <th>{t("devices.lastIp")}</th>
            <th>{t("devices.creator")}</th>
            <th>{t("devices.created")}</th>
            <th>{t("devices.updated")}</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device) => {
            return (
              <tr key={device.id}>
                <td>{device.id}</td>
                <td>{device.name}</td>
                <td>{device.active ? <GoCheck /> : <GoX />}</td>
                <td>{formatDateTime(device.lastSeen)}</td>
                <td>{device.lastIp}</td>
                <td>{device.creator}</td>
                <td>{formatDateTime(device.created)}</td>
                <td>{formatDateTime(device.updated)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const DeviceCreator = () => {
    return (
      <Form onSubmit={createDeviceHandler}>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>{t("devices.name")}</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            disabled={loading}
            value={device.name}
            type="text"
            placeholder='iPad 6 "HR Rep App 01"'
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setDevice({ ...device, name: event.target.value })
            }
          />
          <InputGroup.Append>
            <Button variant="primary" type="submit">
              {t("submit")}
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    );
  };

  const DeviceModal = (props: any) => {
    return (
      <Modal {...props} centered>
        <Modal.Header>
          <Modal.Title>{t("devices.created-header")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("devices.created-description")}</p>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>{t("devices.id")}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control type="text" value={device.id} />
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>{t("devices.secret")}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control type="text" value={device.secret} />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setDevice({ name: "" })}>{t("close")}</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Container className="mt-3">
      {DeviceModal({ show: !!device.secret })}
      <Row>
        <Col sm="12">
          <h4>
            <Trans i18nKey="devices.title" />
          </h4>
          <p>{t("devices.subtitle")}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>{t("new_invitation")}</h5>
        </Col>
      </Row>
      <Row>
        <Col>{DeviceCreator()}</Col>
      </Row>
      <Row>
        <Col className="pt-1">
          <h5>{t("all")}</h5>
        </Col>
      </Row>
      <Row>
        <Col>{DeviceList()}</Col>
      </Row>
    </Container>
  );
};

export default Devices;
