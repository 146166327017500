import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  participant: IParticipant;
  onChangeParticipant: (participant: IParticipant) => void;
  disabled?: boolean;
}

/**
 * Form for the partipants of the meeting.
 */
const ParticipantInfoForm: React.FC<Props> = ({
  participant,
  onChangeParticipant,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col md="4">
          <Form.Group>
            <Form.Label>{t("person.company")}</Form.Label>
            <Form.Control
              disabled={disabled}
              type="text"
              required
              placeholder="Mitsubishi Fuso"
              value={participant.company || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeParticipant({ ...participant, company: e.target.value })
              }
            />
          </Form.Group>
        </Col>
        {!participant.isGuest && (
          <Col md="4">
            <Form.Group>
              <Form.Label>{t("person.department")}</Form.Label>
              <Form.Control
                disabled={disabled}
                type="text"
                required
                placeholder="Department"
                value={participant.department || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeParticipant({
                    ...participant,
                    department: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col md="4">
          <Form.Group>
            <Form.Label>{t("person.title")}</Form.Label>
            <Form.Control
              disabled={disabled}
              as="select"
              value={participant.title || ""}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                onChangeParticipant({ ...participant, title: e.target.value })
              }
            >
              <option value="">
                {participant.isGuest ? t("person.sama") : t("person.san")}
              </option>
              <option value="Mr.">Mr.</option>
              <option value="Ms.">Ms.</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <Form.Label>{t("person.last_name")}</Form.Label>
            <Form.Control
              disabled={disabled}
              type="text"
              required
              placeholder="Last Name"
              value={participant.lastName || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeParticipant({
                  ...participant,
                  lastName: e.target.value,
                })
              }
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <Form.Label>{t("person.first_name")}</Form.Label>
            <Form.Control
              disabled={disabled}
              type="text"
              required
              placeholder="First Name"
              value={participant.firstName || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeParticipant({
                  ...participant,
                  firstName: e.target.value,
                })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Form.Group>
            <Form.Label>{t("person.email")}</Form.Label>
            <Form.Control
              disabled={disabled}
              type="email"
              required
              placeholder="name@company.domain"
              value={participant.email || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeParticipant({ ...participant, email: e.target.value })
              }
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <Form.Label>{t("person.tel")}</Form.Label>
            <Form.Control
              disabled={disabled}
              type="text"
              required
              placeholder="+12 (3)45 6789 0123"
              value={participant.tel || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeParticipant({ ...participant, tel: e.target.value })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      {participant.isGuest && (
        <Row>
          <Col md="12">
            <Form.Group>
              <Form.Label>{t("person.note")}</Form.Label>
              <Form.Control
                disabled={disabled}
                as="textarea"
                rows={1}
                placeholder={t("person.note_description")}
                value={participant.hostNote || ""}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  onChangeParticipant({
                    ...participant,
                    hostNote: e.target.value,
                  })
                }
                maxLength={1000}
              />
              {!disabled && (
                <Form.Text>
                  {participant.hostNote?.length || 0}/{1000} {t("characters")}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ParticipantInfoForm;
