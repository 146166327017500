import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import english from "./en.json";
import japanese from "./ja.json";

// languages available for translations
const resources = {
    en: {
        translation: english,
    },
    ja: {
        translation: japanese,
    },
};

const options = {
    // order and from where user language should be detected
    order: [
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
    ],

    // keys or params to lookup language from
    lookupQuerystring: "lng",
    lookupCookie: "i18next",
    lookupLocalStorage: "i18nextLng",
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ["localStorage", "cookie"],
    excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // only detect languages that are in the whitelist
    checkWhitelist: true,
};

i18n.use(detector)
    .use(initReactI18next)
    .init({
        detection: options,
        fallbackLng: "en",
        resources,

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
