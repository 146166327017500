/**
 * Formats the full name including title or postfix
 */
export function getName(person: IParticipant): string {
  // add -san or -sama if no title is given
  return `${person.title ? `${person.title} ` : ""}${
    person.firstName + " " || ""
  }${person.lastName || ""}${
    person.title ? "" : person.isGuest ? "-sama" : "-san"
  }`;
}

/**
 * Formats the full name including title or postfix based on language
 */
export function i18name(person: IParticipant, lng: string): string {
  if (lng === "ja") {
    return `${person.lastName} ${person.firstName} ${
      person.isGuest ? "様" : "さん"
    }`;
  } else {
    return getName(person);
  }
}

/**
 * Check if profile is complete
 */
export function isComplete(user: IUser) {
  // title can be falsy as logic should handle adding postfixes for empty titles
  return !!(
    user.id &&
    user.email &&
    user.firstName &&
    user.lastName &&
    user.tel &&
    user.department &&
    user.siteCode
  );
}
