import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const DEV = process.env.NODE_ENV === "development"; // debug mode set by env var
const QA = process.env.REACT_APP_NODE_QA === "true"; // qa mode set by env var
/**
 * Show a strip warning if its DEV or QA Environment.
 */
const EnvironmentTag: React.FC = () => {
  if (DEV || QA) {
    return (
      <Container fluid>
        <Row className="text-center">
          <Col className="bg-warning">
            <strong>{`${DEV ? "DEV" : QA ? "QA" : ""} ENVIRONMENT`}</strong>
          </Col>
        </Row>
      </Container>
    );
  }
  return <div />;
};

export default EnvironmentTag;
