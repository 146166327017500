import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function PrivacyPolicy() {
  const { t, i18n } = useTranslation();
  return (
    <Container className="mt-3">
      <h4>{t("privacy-policy")}</h4>
      {i18n.language === "ja" ? <PrivacyPolicyJa /> : <PrivacyPolicyEn />}
    </Container>
  );
}

function PrivacyPolicyEn() {
  return (
    <div>
      <h5>Purposes of Personal Information Usage</h5>
      <p>
        The Company will not use its customers’ personal information other than
        for the purposes listed below.
      </p>
      <ol>
        <li>Contact the employee in charge, and welcome you at the entrance</li>
        <li>Security control for our premises</li>
        <li>Emergency call to your family or place of employment</li>
        <li>Guidance and navigation services for our premises</li>
        <li>
          Other purposes written in our “Policy for Personal Information
          Protection”
          <br />
          ※Details are shown in{" "}
          <a
            href="https://www.mitsubishi-fuso.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            our website
          </a>
        </li>
        <li>In case provision of the information is permitted by law</li>
      </ol>
      <p>
        You can see our “Policy for Personal Information Protection” through{" "}
        <a
          href="https://www.mitsubishi-fuso.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          our website
        </a>
        .
      </p>
    </div>
  );
}

function PrivacyPolicyJa() {
  return (
    <div>
      <h5>個人情報の利用目的</h5>
      <p>
        弊社は、本アプリケーションを通じて取得したお客様の個人情報を下記（１）～（６）の利用目的の範囲で業務の遂行上必要な限りにおいて利用します。
      </p>
      <ol>
        <li>弊社担当社員への連絡、お客様のお出迎え</li>
        <li>弊社構内の安全管理、保安業務</li>
        <li>ご家族、お勤め先等への緊急連絡</li>
        <li>弊社構内のご案内</li>
        <li>
          その他、弊社「個人情報保護についての方針」に記載の利用目的
          <br />
          ※詳細は、
          <a
            href="https://www.mitsubishi-fuso.com/ja/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            弊社ウェブサイト
          </a>
          をご参照ください。
        </li>
        <li>その他、法令の定めにより認められた場合</li>
      </ol>
      <p>
        その他、弊社の個人情報保護に関する方針は、
        <a
          href="https://www.mitsubishi-fuso.com/ja/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          弊社ウェブサイト
        </a>
        をご参照ください。
      </p>
    </div>
  );
}
