import { defaultErrorResponse } from "utils/error";

/**
 * Get all meetings scheduled for today created by one user.
 */
export default async function todayMeetings(
  userId: string,
): Promise<IResponse> {
  try {
    const response = await fetch(`/api/today/${userId}`, {
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
    if (response.status === 200) {
      const data = await response.json();
      return {
        success: true,
        error: "",
        data: data,
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      success: false,
      error: "api-error",
    };
  }
}
