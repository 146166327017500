import { defaultErrorResponse } from "utils/error";

/**
 * Create a device
 */
export default async function createDevice(
  device: IDevice,
): Promise<IResponse> {
  try {
    const response = await fetch("/api/devices", {
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(device),
    });
    if (response.status === 400) {
      return {
        success: false,
        error: "devices.invalid",
      };
    } else if (response.status === 201) {
      const data = await response.json();
      return {
        data,
        redirect: "/devices",
        success: true,
        error: "",
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      redirect: null,
      success: false,
      error: "api-error",
    };
  }
}
