import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import { toast } from "react-toastify";
import queryString from "query-string";
import {
  GoPencil,
  GoCheck,
  GoTrashcan,
  GoX,
  GoPerson,
  GoSync,
} from "react-icons/go";
import { useTranslation } from "react-i18next";
import MeetingInfoForm from "views/partials/MeetingInfoForm";
import HostInfoForm from "views/partials/HostInfoForm";
import GuestInfoForm from "views/partials/GuestInfoForm";
import { addNewGuest } from "utils/meeting";
import { createUpdateMeeting, getMeeting, deleteMeeting } from "api";
import moment from "moment";

interface Params {
  id?: string;
}

/**
 * Page to show/edit all the meeting information, as well as security information for the guards.
 */
const Meeting: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<Params>();
  const guestIdFromURL = location.hash ? location.hash.substring(1) : "";
  const parsed = queryString.parse(location.search);
  const secret = !Array.isArray(parsed.secret) ? parsed.secret || "" : "";
  const [loading, setLoading] = useState(false);
  const [meeting, setMeeting] = useState({} as IMeeting);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    //Fetch meeting
    const fetchMeeting = async () => {
      setLoading(true);
      if (id) {
        const response = await getMeeting(id, secret);
        if (response.success) {
          setMeeting(response.data);
        } else {
          toast.error(t(response.error));
          history.replace("/");
        }
      }
      setLoading(false);
    };
    fetchMeeting();
  }, [update, history, secret, t, id]);

  //Fetch delete meeting
  const deleteRequest = async () => {
    if (meeting.id) {
      setShowDeleteModal(false);
      setLoading(true);
      const response = await deleteMeeting(meeting.id);
      setLoading(false);
      if (response.success) {
        toast.success(t("delete-successfully"));
        history.push("/");
      } else {
        toast.error(t(response.error));
      }
    }
  };

  //Submit new meeting
  const submitMeeting = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    setLoading(true);
    if (form.checkValidity() === false) {
      //Check form
      event.stopPropagation();
    } else {
      const response = await createUpdateMeeting(meeting);
      setEditMode(false);
      if (response.success) {
        toast.success(t("updated-successfully"));
      } else {
        toast.error(t(response.error));
      }
      setUpdate(!update); // Reload meeting to sync with server
    }
    setLoading(false);
  };

  function isNotEditable() {
    return (
      // no editing for meetings with checked in guests
      meeting.participants?.some(
        (participant) => !!participant.visits?.length,
      ) ||
      // no editing for meetings in the past
      moment().isAfter(moment(meeting.end).startOf("day").add(1, "day"))
    );
  }

  const controlButtons = (editMode: boolean) => {
    if (editMode) {
      return (
        <Row>
          <Col>
            <Button
              className="m-1"
              variant="info"
              onClick={() => {
                setEditMode(false);
                setUpdate(!update);
              }}
            >
              <GoX className="mr-1" />
              {t("cancel")}
            </Button>
            <Button
              className="m-1"
              variant="danger"
              onClick={() => setShowDeleteModal(true)}
            >
              <GoTrashcan className="mr-1" />
              {t("delete")}
            </Button>
            <Button
              className="m-1"
              variant="secondary"
              onClick={() => setMeeting(addNewGuest(meeting))}
            >
              <GoPerson className="mr-1" />
              {t("invitation.add_guest")}
            </Button>
            <Button className="m-1" variant="success" type="submit">
              <GoCheck className="mr-1" />
              {t("submit")}
            </Button>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col>
          <Button
            className="mr-1"
            variant="secondary"
            onClick={() => setUpdate(!update)}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-1"
              />
            ) : (
              <GoSync />
            )}
          </Button>
          <Button
            variant="info"
            onClick={() => setEditMode(true)}
            disabled={isNotEditable()}
          >
            <GoPencil className="mr-1" />
            {t("edit")}
          </Button>
        </Col>
      </Row>
    );
  };

  if (loading) {
    return (
      <div className="text-center mt-3">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <Form onSubmit={submitMeeting}>
      <Container className="mt-3">
        <Row>
          <Col md="4" className="relative">
            <div className="sticky">
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>
                    <h4>{t("invitation.share")}</h4>
                  </Card.Title>
                  <Card.Text className="bg-warning rounded p-1">
                    {t("invitation.share_detail")}
                  </Card.Text>
                </Card.Body>
              </Card>
              {controlButtons(editMode)}
            </div>
          </Col>
          <Col md="8">
            <MeetingInfoForm
              meeting={meeting}
              onChange={setMeeting}
              editMode={editMode}
            />
            <HostInfoForm
              meeting={meeting}
              onChange={setMeeting}
              editMode={editMode}
            />
            <GuestInfoForm
              meeting={meeting}
              onChange={setMeeting}
              editMode={editMode}
              updateMeeting={() => setUpdate(!update)}
              scrollToGuestIndex={
                !Array.isArray(guestIdFromURL) && guestIdFromURL
                  ? guestIdFromURL
                  : undefined
              }
            />
          </Col>
        </Row>
      </Container>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("confirm")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("invitation.delete_confirmation")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            {t("cancel")}
          </Button>
          <Button variant="primary" onClick={() => deleteRequest()}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default Meeting;
