import { defaultErrorResponse } from "utils/error";

/**
 * Get user information.
 */
export default async function getUser(): Promise<IResponse> {
  try {
    const response = await fetch(`/api/users/me`, {
      method: "GET",
      credentials: "same-origin",
    });
    if (response.status === 200) {
      const data = await response.json();
      return {
        success: true,
        error: "",
        data: data,
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      redirect: null,
      success: false,
      error: "api-error",
    };
  }
}
