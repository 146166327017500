import { defaultErrorResponse } from "utils/error";

/**
 * Get guest information for the guest invitation.
 */
export default async function getGuest(
  id: string,
  secret: string,
  guestId: string | undefined,
): Promise<IResponse> {
  try {
    const response = await fetch(
      `/api/meetings/${id}/guests/${guestId || 0}?secret=${secret}`,
      {
        credentials: "same-origin",
        method: "GET",
      },
    );
    if (response.status === 200) {
      const data = await response.json();
      return {
        success: true,
        error: "",
        data: data,
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      redirect: null,
      success: false,
      error: "api-error",
    };
  }
}
