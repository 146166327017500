import React, { useState } from "react";
import { Modal, Button, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import pkg from "../../package.json";

interface IProps {
  onHide: () => void;
  show: boolean;
}

/**
 * Modal with detailed information about the application. Version, language, authors and attributions.
 */
const InfoModal: React.FC<IProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [licenseText, setLisenceText] = useState("");

  const getTitle = (page: number) => {
    switch (page) {
      default:
        return t("info.general-information");
      case 1:
        return t("info.open-source-licenses");
    }
  };

  const renderViews = () => {
    if (page === 0) {
      return (
        <ListGroup variant="flush">
          <ListGroup.Item>
            {t("info.version")}
            <small className="modal-right-text">{pkg.version}</small>
          </ListGroup.Item>
          <ListGroup.Item>
            {t("info.language")}
            <small className="modal-right-text">
              <div
                className="modal-link"
                onClick={() => i18n.changeLanguage("ja")}
              >
                {"日本語"}
              </div>
              /
              <div
                className="modal-link"
                onClick={() => i18n.changeLanguage("en")}
              >
                {"English"}
              </div>
            </small>
          </ListGroup.Item>
          <ListGroup.Item
            className="modal-click-list"
            onClick={() => setPage(1)}
          >
            {t("info.open-source-licenses")}
          </ListGroup.Item>
        </ListGroup>
      );
    } else if (page === 1) {
      if (!licenseText) {
        fetch(`${process.env.PUBLIC_URL}/attribution.txt`)
          .then((r) => r.text())
          .then((text) => {
            setLisenceText(text);
          });
      }
      return <p className="modal-text">{licenseText || t("loading")}</p>;
    }
    return;
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        props.onHide();
        setPage(0);
        setLisenceText("");
      }}
    >
      <Modal.Header className="text-center">
        <Modal.Title className="w-100">{getTitle(page)}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-scroll-body">{renderViews()}</Modal.Body>
      <Modal.Footer>
        {page !== 0 && (
          <Button
            className=" mr-auto"
            variant="danger"
            onClick={() => setPage(0)}
          >
            {t("back")}
          </Button>
        )}
        <Button
          onClick={() => {
            props.onHide();
            setPage(0);
            setLisenceText("");
          }}
        >
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;
