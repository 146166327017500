import { defaultErrorResponse } from "utils/error";

/**
 * Get visitors by day
 */
export async function getVisitors(
  from: Date | string,
  to: Date | string,
  unit: "date" | "hour",
): Promise<IResponse> {
  try {
    const response = await fetch(
      `/api/statistics/visitors?from=${from}&to=${to}&unit=${unit}`,
      {
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
      },
    );

    if (response.status === 200) {
      const data = await response.json();
      return {
        success: true,
        error: "",
        data: data,
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      success: false,
      error: "api-error",
    };
  }
}
