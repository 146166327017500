import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/en-sg";
import "moment/locale/ja";
import { Container, Row, Col, Button, Spinner, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import queryString from "query-string";
import { i18name } from "utils/people";
import { GoFile, GoDesktopDownload } from "react-icons/go";
import { BsMap, BsExclamationCircle } from "react-icons/bs";
import { useLocation, useParams, Link } from "react-router-dom";
import { getMeetingPublic, getGuest } from "api";

interface Params {
  id?: string;
  guestId?: string;
}

/**
 * Page to be sent to the guest as a link, containing the QRCode and all the meeting"s information.
 */
const InvitationGuest: React.FC = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { id, guestId } = useParams<Params>();
  const parsed = queryString.parse(location.search);
  const secret = !Array.isArray(parsed.secret) ? parsed.secret || "" : "";
  const [meeting, setMeeting] = useState({} as IMeeting);
  const [guest, setGuest] = useState({} as IParticipant);
  const [loadingMeeting, setLoadingMeeting] = useState(false);
  const [loadingGuest, setLoadingGuest] = useState(false);
  const [error, setError] = useState(false);

  // When page loads, get meeting and guest info
  useEffect(() => {
    const fetchMeetingInfo = async () => {
      if (id) {
        setLoadingMeeting(true);
        const response = await getMeetingPublic(id, secret);
        if (response.success) {
          setMeeting({ ...response.data, participants: [] });
        } else {
          setError(true);
          toast.error(t(response.error));
        }
        setLoadingMeeting(false);
      }
    };

    const fetchGuestInfo = async () => {
      if (id) {
        setLoadingGuest(true);
        const response = await getGuest(id, secret, guestId);
        setLoadingGuest(false);
        if (response.success) {
          setGuest({ ...response.data, email: "" });
        } else {
          setError(true);
          toast.error(t(response.error));
        }
      }
    };

    fetchGuestInfo();
    fetchMeetingInfo();
  }, [id, guestId, secret, t]);

  if (loadingMeeting || loadingGuest) {
    return (
      <div className="text-center mt-3">
        <Spinner animation="border" />
      </div>
    );
  }
  if (error) {
    return <h4 className="text-center m-3">{t("error")}</h4>;
  }

  return (
    <Container className="mt-3">
      <Row>
        <Col sm="4">
          <Link
            to={`/api/meeting/${id}/${secret}/guest/${guestId}/qr.png`}
            target="_blank"
          >
            <img
              src={`/api/meeting/${id}/${secret}/guest/${guestId}/qr`}
              className="img-responsive ie-fix"
              alt="QR code"
            ></img>
          </Link>
          <div className="text-center">
            <Button
              variant="primary"
              onClick={() => window.print()}
              href="#"
              className="m-1"
            >
              <GoFile className="mr-1" />
              {t("invitation_guest.print")}
            </Button>
            <Link
              className="btn btn-secondary"
              to={`/api/meeting/${id}/${secret}/guest/${guestId}/qr.png`}
              download="fuso-meeting-qr.png"
              target="_blank"
            >
              <GoDesktopDownload className="mr-1" />
              {t("invitation_guest.download")}
            </Link>
          </div>
        </Col>
        <Col sm="4" md="8">
          <div>
            <p>
              {i18n.language === "ja" ? (
                <a
                  href={`${process.env.PUBLIC_URL}${location.pathname}?secret=${secret}&lng=en`}
                >
                  English
                </a>
              ) : (
                <a
                  href={`${process.env.PUBLIC_URL}${location.pathname}?secret=${secret}&lng=ja`}
                >
                  日本語
                </a>
              )}
            </p>
            <p>
              {t("invitation_guest.greeting", {
                name: i18name(guest, i18n.language),
              })}
            </p>
            <p>{t("invitation_guest.qrcode_please")}</p>
            <p>
              {t("invitation_guest.validity", {
                from: moment(meeting.start)
                  .locale(i18n.language === "ja" ? "ja" : "en-sg")
                  .format("LLLL"),
                to: moment(meeting.end)
                  .locale(i18n.language === "ja" ? "ja" : "en-sg")
                  .format("LLLL"),
              })}
            </p>
            <p>{t("invitation_guest.id_please")}</p>
            {meeting.building && (
              <p>
                {meeting.room
                  ? t("invitation_guest.go_to.room_in_building", {
                      room: meeting.room,
                      building: meeting.building,
                    })
                  : t("invitation_guest.go_to.building", {
                      building: meeting.building,
                    })}
              </p>
            )}
            <Card className="d-print-none">
              <div className="card-body">
                <h6 className="card-title">
                  <BsMap className="mr-1" />
                  {t("invitation_guest.map")}
                </h6>
                <p className="card-text">
                  {t("invitation_guest.map-gate-prompt")}
                </p>
                <a
                  className="btn btn-primary"
                  href={`https://map.mitsubishi-fuso.com/?mode=route&from=entranceMain&to=${meeting.building}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("main-gate")}
                </a>
                &nbsp;
                <a
                  className="btn btn-primary"
                  href={`https://map.mitsubishi-fuso.com/?mode=route&from=entranceNorth&to=${meeting.building}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("north-gate")}
                </a>
                <p className="card-text">
                  <BsExclamationCircle className="mr-1" />
                  {t("invitation_guest.map-warning")}
                </p>
              </div>
            </Card>
            <p>{t("invitation_guest.print_or_phone")}</p>
            <p>{t("invitation_guest.thank_you")}</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default InvitationGuest;
