import { defaultErrorResponse } from "utils/error";

/**
 * Get all the meetings scheduled for a date (Only for admin and guard roles).
 */
export default async function getMeetingByDate(
  date?: string,
): Promise<IResponse> {
  try {
    const response = await fetch(
      `/api/meeting${date ? `/?date=${date}` : ""}`,
      {
        credentials: "same-origin",
        method: "GET",
      },
    );
    if (response.status === 400) {
      return {
        success: false,
        error: "invalid-meeting",
      };
    } else if (response.status === 200) {
      const data = await response.json();
      return {
        success: true,
        error: "",
        data: data,
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      redirect: null,
      success: false,
      error: "api-error",
    };
  }
}
