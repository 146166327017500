import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Table } from "components";
import { formatDateTime } from "utils/date";
import { i18name } from "utils/people";
import { GoSync } from "react-icons/go";
import { useContextUser } from "context/UserContext";
import { todayMeetings, upcomingMeetings } from "api";
import { toast } from "react-toastify";
import { sortByCheckin } from "utils/sort";
import { invitationHasHostAndGuest } from "utils/filter";
import { latestVisit } from "utils/visits";

/**
 * Page to show table with all the invitations related to the user.
 */
const Invitations: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [user] = useContextUser();
  const [loading, setLoading] = useState(false);
  const [meetings, setMeetings] = useState<any[]>([]);
  const [update, setUpdate] = useState(false);
  const [period, setPeriod] = useState("today");

  const headers = [
    {
      Header: t("invitation.start"),
      accessor: "start",
    },
    {
      Header: t("invitation.end"),
      accessor: "end",
    },
    {
      Header: t("guest"),
      accessor: "guest",
    },
    {
      Header: t("visit.checked_in"),
      accessor: "checkin",
      sortType: React.useMemo(() => sortByCheckin, []),
    },
  ];

  // When page loads, get meetings from today
  useEffect(() => {
    // Parse meetings to fill customized table
    const parseMeetings = (invitations: IMeeting[]) => {
      return (
        invitations
          // Hotfix: filter out invitations that do not have at least one host and one guest
          .filter(invitationHasHostAndGuest)
          .map((meeting: IMeeting) => {
            const guestList: IParticipant[] = meeting.participants.filter(
              (participant: IParticipant) => participant.isGuest,
            );
            if (guestList.length > 1) {
              return {
                start: formatDateTime(meeting.start),
                end: formatDateTime(meeting.end),
                guest: t("multiple_guests"),
                checkin: `${
                  guestList.filter(
                    (guest: IParticipant) => latestVisit(guest.visits)?.checkIn,
                  ).length
                }/${guestList.length}`,
                subRows: guestList.map((guest: IParticipant) => {
                  return {
                    meetingId: meeting.id,
                    guestId: guest.id,
                    secret: meeting.secret,
                    start: formatDateTime(meeting.start),
                    end: formatDateTime(meeting.end),
                    guest:
                      i18name(guest, i18n.language) +
                      " (" +
                      guest.company +
                      ")",
                    checkin:
                      latestVisit(guest.visits)?.checkIn &&
                      latestVisit(guest.visits)?.checkIn !==
                        "0000-00-00 00:00:00"
                        ? formatDateTime(
                            latestVisit(guest.visits)?.checkIn || "",
                          )
                        : "X",
                  };
                }),
              };
            } else {
              const guest = guestList[0]; //Only one guest
              return {
                meetingId: meeting.id,
                guestId: guest.id,
                secret: meeting.secret,
                start: formatDateTime(meeting.start),
                end: formatDateTime(meeting.end),
                guest: `${i18name(guest, i18n.language)} (${guest.company})`,
                checkin:
                  latestVisit(guest.visits)?.checkIn &&
                  latestVisit(guest.visits)?.checkIn !== "0000-00-00 00:00:00"
                    ? formatDateTime(latestVisit(guest.visits)?.checkIn || "")
                    : "X",
                subRows: [],
              };
            }
          })
      );
    };

    // Fetch today meetings
    const today = async () => {
      setLoading(true);
      const response = await todayMeetings(user.id);
      if (response.success) {
        setMeetings(parseMeetings(response.data));
      } else {
        toast.error(t(response.error));
      }
      setLoading(false);
    };

    // Fetch upcoming meetings
    const upcoming = async () => {
      setLoading(true);
      const response = await upcomingMeetings(user.id);
      if (response.success) {
        setMeetings(parseMeetings(response.data));
      } else {
        toast.error(t(response.error));
      }
      setLoading(false);
    };

    switch (period) {
      case "today":
        today();
        break;
      case "upcoming":
        upcoming();
        break;
    }
  }, [period, t, user.id, i18n.language, update]);

  return (
    <Container className="mt-3">
      <Row>
        <Col
          sm="12"
          data-toggle="popover"
          title={t("popover.meeting_day.title")}
          data-placement="bottom"
          data-content={t("popover.meeting_day.content")}
        >
          <h4>
            {t("invitations.page_title", {
              name: i18name(user, i18n.language),
            })}
          </h4>
          <p>{t("invitations.page_subtitle")}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            headers={headers}
            data={meetings}
            loading={loading}
            headerComponent={
              <>
                <Button
                  className="mr-1 mt-1"
                  variant="secondary"
                  onClick={() => setUpdate(!update)}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-1"
                    />
                  ) : (
                    <GoSync />
                  )}
                </Button>
                <Button
                  className="mr-1 mt-1"
                  variant={"today" === period ? "primary" : "secondary"}
                  onClick={() => setPeriod("today")}
                >
                  {t("today")}
                </Button>
                <Button
                  className="mr-1 mt-1"
                  variant={"upcoming" === period ? "primary" : "secondary"}
                  onClick={() => setPeriod("upcoming")}
                >
                  {t("upcoming")}
                </Button>
              </>
            }
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Invitations;
