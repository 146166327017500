/**
 * Call this at the end of your custom error response handling to identify default error responses.
 *
 * TODO: Allow for plugging in custom handling, and unify error handling here.
 */
export function defaultErrorResponse(response: Response): IResponse {
  switch (response.status) {
    case 401:
      return {
        success: false,
        error: "invalid-credentials",
      };
    case 403:
      return {
        success: false,
        error: "forbidden-access",
      };
    case 404:
      return {
        success: false,
        error: "not-found",
      };
    case 410:
      return {
        success: false,
        error: "past-meeting",
      };
    case 500:
      return {
        success: false,
        error: "api-error",
      };
    default:
      return {
        success: false,
        error: "unknown-response",
      };
  }
}
