import { defaultErrorResponse } from "utils/error";

/**
 * Get meeting information by ID.
 */
export default async function getMeeting(
  id: string,
  secret: string,
): Promise<IResponse> {
  try {
    const response = await fetch(
      `/api/meeting/${id}${secret ? `/${secret}` : ""}`,
      {
        credentials: "same-origin",
        method: "GET",
      },
    );
    if (response.status === 400) {
      return {
        success: false,
        error: "invalid-meeting",
      };
    } else if (response.status === 200) {
      const data = await response.json();
      return {
        redirect: `meeting/${data.id}/guest/0?secret=${data.secret}`,
        success: true,
        error: "",
        data: data,
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      redirect: null,
      success: false,
      error: "api-error",
    };
  }
}
