import { defaultErrorResponse } from "utils/error";

/**
 * Get meeting information for the guest invitation.
 */
export default async function getMeetingPublic(
  id: string,
  secret: string,
): Promise<IResponse> {
  try {
    const response = await fetch(`/api/meetings/${id}/guest?secret=${secret}`, {
      method: "GET",
    });
    if (response.status === 200) {
      const data = await response.json();
      return {
        success: true,
        error: "",
        data: data,
      };
    }
    return defaultErrorResponse(response);
  } catch (e) {
    console.error(e);
    return {
      redirect: null,
      success: false,
      error: "api-error",
    };
  }
}
