import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { updateUserProfile, getUser } from "api";
import { toast } from "react-toastify";
import { isComplete } from "utils/people";
import { useContextUser } from "context/UserContext";
import { GoCheck, GoSignOut, GoX } from "react-icons/go";

/**
 * Profile page.
 */
const Profile: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useContextUser();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      const response = await getUser();
      if (response.success) {
        setUser(response.data);
        if (!isComplete(response.data)) {
          toast.warn(t("information-incomplete"));
        }
      } else {
        toast.error(t(response.error));
      }
      setLoading(false);
    };

    fetchUser();
  }, [t, setUser]);

  //Update profile
  const submitProfile = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    setLoading(true);
    if (form.checkValidity() === false) {
      //Check form
      event.stopPropagation();
    } else {
      const response = await updateUserProfile(user);
      if (response.success) {
        setUser(response.data);
        toast.success(t("updated-successfully"));
        const redirectAfterLogin = localStorage.getItem("redirect");
        if (redirectAfterLogin) {
          // Redirect right after the user has logged in
          localStorage.removeItem("redirect");
          history.push(redirectAfterLogin);
        }
      } else {
        toast.error(t(response.error));
      }
    }
    setLoading(false);
  };

  return (
    <Container className="mt-3">
      {/* profile */}
      <Form onSubmit={submitProfile}>
        <Row>
          <Col sm="12">
            <h3>{t("profile.page_title")}</h3>
            <p>{t("profile.page_subtitle")}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>{t("profile.personal_info")}</h4>
            <Row>
              <Col md="4">
                <Form.Group>
                  <Form.Label>{t("person.title")}</Form.Label>
                  <Form.Control
                    disabled={loading}
                    as="select"
                    value={user.title || ""}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setUser({ ...user, title: e.target.value })
                    }
                  >
                    <option value="">{t("person.san")}</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <Form.Label>{t("person.last_name")}</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    required
                    placeholder="Mustermann"
                    value={user.lastName || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUser({ ...user, lastName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <Form.Label>{t("person.first_name")}</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    required
                    placeholder="Max"
                    value={user.firstName || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUser({ ...user, firstName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <h4>{t("profile.work_title")}</h4>
            <Row>
              <Col md="4">
                <Form.Group>
                  <Form.Label>{t("person.site")}</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    required
                    placeholder="575"
                    value={user.siteCode || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUser({ ...user, siteCode: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <Form.Label>{t("person.department")}</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    required
                    placeholder="ITC/AC"
                    value={user.department || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUser({ ...user, department: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <Form.Label>{t("person.department_description")}</Form.Label>
                  <Form.Control
                    disabled={loading}
                    type="text"
                    placeholder="IT Process Management"
                    value={user.departmentDescription || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUser({
                        ...user,
                        departmentDescription: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <h5>{t("profile.contact_title")}</h5>
            <Row>
              <Col md="4">
                <Form.Group>
                  <Form.Label>{t("person.email")}</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="email"
                    required
                    placeholder="max.mustermensch@daimlertruck.com"
                    value={user.email || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUser({ ...user, email: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <Form.Label>{t("person.tel")}</Form.Label>
                  <Form.Control
                    disabled={loading}
                    type="text"
                    required
                    placeholder="+81 (0)44 1234-5678"
                    value={user.tel || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUser({ ...user, tel: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Button
              className="mr-1"
              onClick={() => history.push("/")}
              variant="secondary"
              disabled={loading}
            >
              <GoX className="mr-1" />
              {t("cancel")}
            </Button>
            <Button variant="success" type="submit" disabled={loading}>
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
              ) : (
                <GoCheck className="mr-1" />
              )}
              {t("submit")}
            </Button>
          </Col>
        </Row>
      </Form>
      {/* logout */}
      <Row className="mt-3">
        <Col>
          <h3>{t("profile.logout_title")}</h3>
          <p>{t("profile.logout_subtitle")}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            // logout is a backend route and cannot be reached using the client side router
            onClick={() => window.location.assign("/logout")}
            title={t("profile.logout")}
            variant="danger"
          >
            <GoSignOut /> {t("profile.logout_title")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
